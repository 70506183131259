import React, {
  ElementType,
} from 'react';

import { useRouter } from 'next/router';

import {
  SegmentScript,
  useSegmentPageTransition,
} from '@waglabs/segment';

import {
  theme,
  GlobalStyle as BaseThemeGlobalStyle,
} from '@waglabs/base-theme';
import { ThemeProvider } from 'styled-components';

import { GlobalStyle } from '../styles/global-style';
import { NewRelicBrowserScript } from '../components/NewRelicBrowserScript';

import {
  ENV_SEGMENT_KEY,
  ENV_NEWRELIC_BROWSER_ACCOUNT_ID,
  ENV_NEWRELIC_BROWSER_AGENT_ID,
  ENV_NEWRELIC_BROWSER_APPLICATION_ID,
  ENV_NEWRELIC_BROWSER_LICENSE_KEY,
  ENV_NEWRELIC_BROWSER_TRUST_KEY,
} from '../constants';

type MyAppProps = {
  Component: ElementType,
  pageProps: Record<string, unknown>,
};

const MyApp = ({
  Component,
  pageProps,
}: MyAppProps) => {
  const router = useRouter();

  useSegmentPageTransition(router.asPath, {
    delayTracking: 800,
  });

  return (
    <ThemeProvider
      theme={theme}
    >
      <SegmentScript
        segmentKey={ENV_SEGMENT_KEY}
      />
      <NewRelicBrowserScript
        accountId={ENV_NEWRELIC_BROWSER_ACCOUNT_ID}
        agentId={ENV_NEWRELIC_BROWSER_AGENT_ID}
        applicationId={ENV_NEWRELIC_BROWSER_APPLICATION_ID}
        licenseKey={ENV_NEWRELIC_BROWSER_LICENSE_KEY}
        trustKey={ENV_NEWRELIC_BROWSER_TRUST_KEY}
      />
      <BaseThemeGlobalStyle />
      <GlobalStyle />
      <Component
        {...pageProps}
      />
    </ThemeProvider>
  );
};

export default MyApp;
